html {
  /*margin: @main-menu-width*/
}
.demo-credentials-info {
  position: relative;
  z-index: 999;
  padding: 10px;
  border-radius: 3px;
  background: whitesmoke;
  width: 30%;
  margin: 50px auto 50px;
  border: 1px solid #1b809e;
  border-left-width: 5px;
}
.header-credentials-info {
  font-size: 17px;
  color: #1b809e;
  font-weight: bold;
}
